import React, {useState, useRef} from "react";
import styles from './style.module.scss'
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha"
import {SendedSection} from "./SendedSection";

export const FormSection = () => {

    // const submitted = props.submitted;
    const [submitted, setSubmitted] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const recaptchaRef = React.createRef();
    const form = useRef();

    const sendEmail = async (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1waip5h', 'template_8knhy2t', form.current, '5b7hhJUUx0B1tzsdK')
            .then((result) => {
                console.log(result.text);
                setSubmitted(true);
                }, (error) => {
                console.log(error.text);
            });
        setTimeout(() => {
            setSubmitted(true);
        }, 100);
    };

    function handleOnChange(value) {
        setIsVerified(true);
        console.log(isVerified);
    }

    return (
        <>
        {!submitted ?
        // TODO action endpoitn ???
        <form className={styles.form}
              ref={form}
              onSubmit={sendEmail}
              id="contact-form"
              method="POST"
              target="_blank"
        >
            <div className={styles.form_inputs}>
                <h2 className={styles.contact_header}>Napište nám</h2>
                <label>
                    <input type="text"
                           name="name"
                           placeholder={"Jméno"}
                           className={styles.input}
                           required
                    />
                </label>
                <label>
                    <input type="text"
                           name="email"
                           placeholder={"Email"}
                           className={styles.input}
                           required/>
                </label>
                <label>
                    <input type="text"
                           name="phone"
                           placeholder={"Telefon"}
                           className={styles.input}
                           required/>
                </label>
                <label>
                    <textarea name="message"
                              rows="12" cols="60"
                              placeholder={"Zpráva"}
                              className={styles.input}
                              required>
                    </textarea>
                </label>
                <div className={styles.reCaptcha}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lehs4UiAAAAAIJ9YOOtCjYF90S0wGydK8KQg2TG"
                        onChange={handleOnChange}
                    />
                </div>
                <input
                    disabled={!isVerified}
                    className={styles.submit_form}
                       type="submit"
                       name="send"
                       value="Odeslat"/>
            </div>
        </form>
        :
        <SendedSection/>}
        </>
    );
}