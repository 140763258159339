import styles from './style.module.scss'
import logo from './../../assets/logo1_mini_mini.webp'

export const MainSection = () => {

  return (
    <div className={styles.container} data-bg={'container'} id={'main-section'}>
      <div className={styles.content}>
        <div className={styles.main_content}>
          <img src={logo} fetchpriority={"high"} alt={'logo'} width={'0'} height={'0'}/>
        </div>
        <div className={styles.minor_content}>
          Realizujeme kvalitní pokládky dlažby v Praze a Středočeském kraji.
        </div>
      </div>
    </div>
  )
}