import styles from './style.module.scss'
import {Link, useParams, useNavigate} from "react-router-dom";
import {Backbutton} from "./assets/backbutton";
import {useEffect} from "react";

import bg1 from "../galerySection/assets/headers/img6.webp";
import bg2 from "../galerySection/assets/headers/img6.webp";
import bg3 from "../galerySection/assets/headers/img6.webp";
import bg4 from "../galerySection/assets/headers/img6.webp";
import bg5 from "../galerySection/assets/headers/img6.webp";
import bg6 from "../galerySection/assets/headers/img6.webp";

const cities = [
    {
        "id": 1,
        "city": "Neratovice-Lobkovice",
        "address": "Neratovice-Lobkovice, Palackého\n" +
            "34, 277 11",
        "img": bg1,
        "iframe": null,
    },
    {
        "id": 2,
        "city": "Masarykovo náměstí",
        "address": "Brandýs nad Labem-Stará Boleslav, Masarykovo nám. \n" +
            "7-6, 250 01",
        "img": bg2,
        "iframe": <iframe
            title="Brandys-Stara Boleslav"
            src="https://www.google.com/maps/embed?pb=!4v1662925974190!6m8!1m7!1sjgLTwsHAK6y1Lp48g5Zk8w!2m2!1d50.18612757127509!2d14.65897981247434!3f119.88879281905712!4f-6.558606324278458!5f0.7820865974627469"
            loading="lazy"
            cancelable="false"
            referrerPolicy="no-referrer-when-downgrade"/>,
    },
    {
        "id": 3,
        "city": "Žižkov",
        "address": "Praha 3-Žižkov, Basilejské náměstí \n" +
            "3-1, 130 00",
        "img": bg3,
        "iframe": null,
    },
    {
        "id": 4,
        "city": "Náměstí republiky",
        "address": "Praha 1, Nové město, Na Poříčí\n" +
            "110 00",
        "img": bg4,
        "iframe": <iframe
            title="Praha, Náměstí Republiky"
            src="https://www.google.com/maps/embed?pb=!4v1662926693000!6m8!1m7!1svAghDQ81FWHtxkJ6Rm75iQ!2m2!1d50.08823048384813!2d14.42840016152041!3f43.88036866941661!4f-8.238810034552557!5f0.7820865974627469"
            loading="lazy"
            cancelable="false"
            referrerPolicy="no-referrer-when-downgrade"/>,
    },
    {
        "id": 5,
        "city": "Negrelliho Viadukt",
        "address": "Praha 8, Karlín, Prvního pluku\n" +
            "186 00",
        "img": bg5,
        "iframe": null,
    },
    {
        "id": 6,
        "city": "Hellichova ulice",
        "address": "Praha 1, Malá Strana\n" +
            "118 00",
        "img": bg6,
        "iframe": <iframe
            title="Praha, Hellichova ulice"
            src="https://www.google.com/maps/embed?pb=!4v1662926901936!6m8!1m7!1sMjj80dZ906uqbsim6eXZmQ!2m2!1d50.08433561098805!2d14.40426732194758!3f271.8076362168112!4f-17.659135592171523!5f0.7820865974627469"
            loading="lazy"
            cancelable="false"
            referrerPolicy="no-referrer-when-downgrade"/>,
    },
]

export const StreetView = () => {

    const params = useParams();
    const id = params.mestoId - 1;

    const city = cities[id];
    const bg = city.img;

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <img className={styles.main_img} alt={'img'} src={bg}/>
                <div className={styles.headerTopic}>{city.city}</div>
            </header>
            <main className={styles.content}>
                <div className={styles.main_content}>
                    {(city.iframe) ?
                        <div>
                            {city.iframe}
                            <button type={'button'}
                                    onClick={() => {
                                        window.location.reload()
                                    }}
                            >Vrátit se na původní pozici
                            </button>
                        </div> :
                        <p className={styles.margin_after}>Omlouváme se, ale náhled není v tuto chvíli k dispozici.</p>
                    }
                </div>
                <div className={styles.bottom_content}>
                    <Backbutton onClick={() => {
                        navigate(-1)
                    }}/>
                    <div>{city.address}</div>
                    <Link to={`/galerie/${city.id}`} className={styles.link}>Přejít do galerie</Link>
                </div>
            </main>
        </div>
    )
}