import styles from './style.module.scss'
import docs from './assets/docs.png'
import info from './assets/info.png'
import galery from './assets/galery.png'
import {HashLink} from "react-router-hash-link";

export const AboutUsSection = () => {

    return (
        <div className={styles.aboutUsContainer} id={"aboutUs"}>
            <div className={styles.aboutUsBackground}>
                <div id={styles.p1}>
                    <p>
                        Naše společnost působí na trhu od roku 2006. Zabývá se pokládkou venkovní dlažby všeho
                        druhu. Provádíme pokládky chodníků, komunikací, parkovišť nebo kdekoli, kde si zákazník
                        vyžádá. Hlavním cílem je plnění požadavků a přání našich zákazníků.
                    </p>
                    <img src={info} alt={'information'}/>
                </div>
                <div id={styles.p2}>
                    <img src={docs} alt={'documents'}/>
                    <p>
                        Firma má navázanou úzkou spolupráci se společnostmi OK&nbsp;Stavby&nbsp;s.r.o., OK&nbsp;Recyklace&nbsp;s.r.o.,
                        M&nbsp;-&nbsp;SILNICE&nbsp;a.s., Eurovia&nbsp;CS&nbsp;a.s., HOCHTIEF&nbsp;CZ&nbsp;a.s.,
                        SWIETELSKY&nbsp;stavební&nbsp;s.r.o., Technickou správou komunikací
                        hlavního města Prahy a obcemi ve Středočeském kraji.
                    </p>
                </div>
                <div id={styles.p3}>
                    <p>
                        Naše odvedená práce mluví za vše, podívejte se na referenční stavby v&nbsp;
                        <HashLink smooth to={"/#galery"} className={styles.underline}
                        >galerii</HashLink>
                        .</p>
                    <img src={galery} alt={'mail'}/>
                </div>
            </div>
        </div>
    )
}