import styles from "./style.module.scss";
import React, {useState, useRef} from "react";

export const SendedSection = () => {
    return (
        <form className={styles.form} id={styles.center_div}>
            <div id={styles.sendedInfo}>
                <div>
                    Děkujeme Vám za zprávu.
                </div>
                <div>
                    V blízké době Vám zašleme naši odpověď.
                </div>
            </div>
        </form>
    );
}