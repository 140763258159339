import styles from './style.module.scss'
import logo from './../../assets/logo1_mini_mini.webp'
import burgerMenu from './assets/white-hamburger-icon-8.png'
import {useState} from 'react'
import {HashLink} from "react-router-hash-link";

export const NavigationSection = () => {

  const [showMenu, setShowMenu] = useState(false);

  let menu;

  if (showMenu) {
    menu = <nav className={styles.mobile_navigation_links}>
      <HashLink smooth to={"/#aboutUs"}>
        <div onClick={() => setShowMenu(!showMenu)}
        >O nás
        </div>
      </HashLink>

      <HashLink smooth to={"/#galery"}>
        <div onClick={() => setShowMenu(!showMenu)}
        >Referenční stavby
        </div>
      </HashLink>

      <HashLink smooth to={"/#eco"}>
        <div onClick={() => setShowMenu(!showMenu)}
        >Udržitelnost
        </div>
      </HashLink>

      <HashLink smooth to={"/#contact"}>
        <div onClick={() => setShowMenu(!showMenu)}
        >Kontakt
        </div>
      </HashLink>

    </nav>
  } else {
    menu = <div>

    </div>
  }

  return (
    <div className={styles.nav_container}>
      <div className={styles.container}>
        <div className={styles.logo_container}>
          <HashLink smooth to={"/#home"}>
            <img src={logo} className={styles.nav_logo} alt={'logo'} width={'0'} height={'0'}/>
          </HashLink>
        </div>
        <nav className={styles.navigation_links}>
          <div>
            <HashLink smooth to="/#aboutUs"
            >O nás</HashLink>
          </div>
          <div>
            <HashLink smooth to={"/#galery"}
            >Referenční stavby</HashLink>
          </div>
          <div>
            <HashLink smooth to={"/#eco"}
            >Udržitelnost</HashLink>
          </div>
          <div>
            <HashLink smooth to={"/#contact"}
            >Kontakt</HashLink>
          </div>
        </nav>
        <div id={styles.burgerMenu}>
          <img src={burgerMenu} alt={'hamburger menu'}
               onClick={() => setShowMenu(!showMenu)}
          />
          {/*<FontAwesomeIcon*/}
          {/*    icon={menu}*/}
          {/*    onClick={() => setShowMenu(!showMenu)}*/}
          {/*/>*/}
        </div>
      </div>
      {menu}
    </div>
  )
}