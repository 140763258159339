export const Pointer = props => {
    return (
        <svg className={props.style.svgIcon} xmlns="http://www.w3.org/2000/svg" width="115" height="115" fill="none" viewBox="0 0 115 115">
            <circle cx="57.5" cy="57.5" r="57.5" fill="#585B6A"/>
            <path fill="#fff" fillRule="evenodd"
                  d="M57.39 77.816C60.44 76.344 80.333 66.054 80.333 48c0-12.887-10.447-23.333-23.334-23.333-12.886 0-23.333 10.446-23.333 23.333 0 18.054 19.892 28.344 22.943 29.816a.868.868 0 0 0 .78 0ZM57 58c5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10s4.477 10 10 10Z"
                  clipRule="evenodd"/>
            <path stroke="#fff" strokeLinecap="round" strokeWidth="4"
                  d="M77.207 73c2.047 1.52 3.126 3.245 3.126 5s-1.079 3.48-3.126 5c-2.048 1.52-4.994 2.783-8.541 3.66-3.547.878-7.57 1.34-11.667 1.34-4.096 0-8.12-.462-11.666-1.34-3.547-.877-6.493-2.14-8.54-3.66-2.049-1.52-3.127-3.245-3.127-5s1.078-3.48 3.126-5"/>
        </svg>
    )
}

export const Album = props => {
    return (
        <svg className={props.style.svgIcon} xmlns="http://www.w3.org/2000/svg" width="115" height="115" fill="none"
             viewBox="0 0 115 115">
            <circle cx="57.5" cy="57.5" r="57.5" fill="#585B6A"/>
            <circle cx="73.667" cy="38" r="6.667" fill="#fff"/>
            <path fill="#fff" stroke="#fff" strokeWidth="2"
                  d="M42.877 48.103 27.72 60.733c-.354.296-.531.443-.626.645-.094.201-.094.431-.094.892v18.397c0 1.885 0 2.828.586 3.414.586.586 1.528.586 3.414.586h48.667c1.885 0 2.828 0 3.414-.586.586-.586.586-1.529.586-3.414V68.829c0-.41 0-.614-.076-.797-.077-.184-.221-.329-.51-.618l-9.92-9.919c-1.333-1.333-2-2-2.828-2-.828 0-1.495.667-2.828 2l-8.416 8.416c-.947.947-1.42 1.42-1.94 1.32-.522-.1-.785-.716-1.313-1.946L49.114 49.6c-.968-2.26-1.452-3.39-2.428-3.624-.976-.234-1.92.553-3.81 2.127Z"/>
        </svg>
    )
}