import styles from './style.module.scss'
import {Link, useNavigate, useParams} from "react-router-dom";
import {Backbutton} from "../streetView/assets/backbutton";

import bg1 from "./assets/headers/img1.webp"
import bg2 from "./assets/headers/img2.webp"
import bg3 from "./assets/headers/img3.webp"
import bg5 from "./assets/headers/img4.webp"
import bg4 from "./assets/headers/img5.webp"
import bg6 from "./assets/headers/img6.webp"

import pal1 from "./assets/palackeho/DEF02011.webp"
import pal2 from "./assets/palackeho/DEF02012.webp"
import pal3 from "./assets/palackeho/DEF02013.webp"
import pal4 from "./assets/palackeho/DEF02015.webp"
import pal5 from "./assets/palackeho/DEF02017.webp"
import pal6 from "./assets/palackeho/DEF02018.webp"

import pal1_mini from "./assets/palackeho/DEF02011_mini.webp"
import pal2_mini from "./assets/palackeho/DEF02012_mini.webp"
import pal3_mini from "./assets/palackeho/DEF02013_mini.webp"
import pal4_mini from "./assets/palackeho/DEF02015_mini.webp"
import pal5_mini from "./assets/palackeho/DEF02017_mini.webp"
import pal6_mini from "./assets/palackeho/DEF02018_mini.webp"

import bra1 from "./assets/brandys/DEF02019.webp"
import bra2 from "./assets/brandys/DEF02021.webp"
import bra3 from "./assets/brandys/DEF02023.webp"
import bra4 from "./assets/brandys/DEF02024.webp"
import bra5 from "./assets/brandys/DEF02027.webp"
import bra6 from "./assets/brandys/DEF02028.webp"
import bra7 from "./assets/brandys/DEF02029.webp"

import bra1_mini from "./assets/brandys/DEF02019_mini.webp"
import bra2_mini from "./assets/brandys/DEF02021_mini.webp"
import bra3_mini from "./assets/brandys/DEF02023_mini.webp"
import bra4_mini from "./assets/brandys/DEF02024_mini.webp"
import bra5_mini from "./assets/brandys/DEF02027_mini.webp"
import bra6_mini from "./assets/brandys/DEF02028_mini.webp"
import bra7_mini from "./assets/brandys/DEF02029_mini.webp"

import bas1_mini from "./assets/basilej/DEF02031_mini.webp"
import bas2_mini from "./assets/basilej/DEF02032_mini.webp"
import bas3_mini from "./assets/basilej/DEF02033_mini.webp"
import bas4_mini from "./assets/basilej/DEF02034_mini.webp"
import bas5_mini from "./assets/basilej/DEF02035_mini.webp"
import bas6_mini from "./assets/basilej/DEF02036_mini.webp"

import bas1 from "./assets/basilej/DEF02031.webp"
import bas2 from "./assets/basilej/DEF02032.webp"
import bas3 from "./assets/basilej/DEF02033.webp"
import bas4 from "./assets/basilej/DEF02034.webp"
import bas5 from "./assets/basilej/DEF02035.webp"
import bas6 from "./assets/basilej/DEF02036.webp"

import rep1 from "./assets/paladium/DEF02041.webp"
import rep2 from "./assets/paladium/DEF02042.webp"
import rep3 from "./assets/paladium/DEF02043.webp"
import rep4 from "./assets/paladium/DEF02044.webp"
import rep5 from "./assets/paladium/DEF02045.webp"
import rep6 from "./assets/paladium/DEF02047.webp"
import rep7 from "./assets/paladium/DEF02048.webp"
import rep9 from "./assets/paladium/DEF02051.webp"
import rep10 from "./assets/paladium/DEF02054.webp"
import rep11 from "./assets/paladium/DEF02055.webp"
import rep12 from "./assets/paladium/DEF02056.webp"
import rep13 from "./assets/paladium/DEF02057.webp"

import rep1_mini from "./assets/paladium/DEF02041_mini.webp"
import rep2_mini from "./assets/paladium/DEF02042_mini.webp"
import rep3_mini from "./assets/paladium/DEF02043_mini.webp"
import rep4_mini from "./assets/paladium/DEF02044_mini.webp"
import rep5_mini from "./assets/paladium/DEF02045_mini.webp"
import rep6_mini from "./assets/paladium/DEF02047_mini.webp"
import rep7_mini from "./assets/paladium/DEF02048_mini.webp"
import rep9_mini from "./assets/paladium/DEF02051_mini.webp"
import rep10_mini from "./assets/paladium/DEF02054_mini.webp"
import rep11_mini from "./assets/paladium/DEF02055_mini.webp"
import rep12_mini from "./assets/paladium/DEF02056_mini.webp"
import rep13_mini from "./assets/paladium/DEF02057_mini.webp"

import via1 from "./assets/viadukt/DEF02037.webp"
import via2 from "./assets/viadukt/DEF02040.webp"
import via3 from "./assets/viadukt/DEF02039.webp"
import via4 from "./assets/viadukt/DEF02038.webp"

import via1_mini from "./assets/viadukt/DEF02037_mini.webp"
import via2_mini from "./assets/viadukt/DEF02040_mini.webp"
import via3_mini from "./assets/viadukt/DEF02039_mini.webp"
import via4_mini from "./assets/viadukt/DEF02038_mini.webp"

import hel1 from "./assets/hellichova/DEF02058.webp"
import hel2 from "./assets/hellichova/DEF02059.webp"
import hel3 from "./assets/hellichova/DEF02060.webp"
import hel4 from "./assets/hellichova/DEF02061.webp"
import hel5 from "./assets/hellichova/DEF02062.webp"
import hel6 from "./assets/hellichova/DEF02065.webp"
import hel7 from "./assets/hellichova/DEF02066.webp"

import hel1_mini from "./assets/hellichova/DEF02058_mini.webp"
import hel2_mini from "./assets/hellichova/DEF02059_mini.webp"
import hel3_mini from "./assets/hellichova/DEF02060_mini.webp"
import hel4_mini from "./assets/hellichova/DEF02061_mini.webp"
import hel5_mini from "./assets/hellichova/DEF02062_mini.webp"
import hel6_mini from "./assets/hellichova/DEF02065_mini.webp"
import hel7_mini from "./assets/hellichova/DEF02066_mini.webp"

import "../../../node_modules/lightbox2/dist/js/lightbox-plus-jquery.min.js"
import "./lightbox-edit.css"
import {useEffect} from "react";


const cities = [
  {
    "id": 1,
    "city": "Neratovice-Lobkovice",
    "address": "Neratovice-Lobkovice, Palackého\n" +
      "34, 277 11",
    "img": bg1,
    "images": [
      {high: pal1, low: pal1_mini}, {high: pal2, low: pal2_mini}, {high: pal3, low: pal3_mini}, {
        high: pal4,
        low: pal4_mini
      }, {high: pal5, low: pal5_mini}, {high: pal6, low: pal6_mini}
    ],
  },
  {
    "id": 2,
    "city": "Masarykovo náměstí",
    "address": "Brandýs nad Labem-Stará Boleslav, Masarykovo nám. \n" +
      "7-6, 250 01",
    "img": bg2,
    "images": [
      {high: bra1, low: bra1_mini}, {high: bra2, low: bra2_mini}, {high: bra3, low: bra3_mini}, {
        high: bra4,
        low: bra4_mini
      }, {high: bra5, low: bra5_mini}, {high: bra6, low: bra6_mini}, {high: bra7, low: bra7_mini}
    ],
  },
  {
    "id": 3,
    "city": "Žižkov",
    "address": "Praha 3-Žižkov, Basilejské náměstí \n" +
      "3-1, 130 00",
    "img": bg3,
    "images": [
      {high: bas1, low: bas1_mini}, {high: bas2, low: bas2_mini}, {high: bas3, low: bas3_mini}, {
        high: bas4,
        low: bas4_mini
      }, {high: bas5, low: bas5_mini}, {high: bas6, low: bas6_mini}
    ],
  },
  {
    "id": 4,
    "city": "Náměstí republiky",
    "address": "Praha 1, Nové město, Na Poříčí\n" +
      "110 00",
    "img": bg4,
    "images": [
      {high: rep1, low: rep1_mini}, {high: rep2, low: rep2_mini}, {high: rep3, low: rep3_mini}, {
        high: rep4,
        low: rep4_mini
      }, {high: rep5, low: rep5_mini}, {
        high: rep6,
        low: rep6_mini
      }, {high: rep7, low: rep7_mini}, {high: rep9, low: rep9_mini}, {high: rep10, low: rep10_mini}, {
        high: rep11,
        low: rep11_mini
      }, {
        high: rep12,
        low: rep12_mini
      }, {high: rep13, low: rep13_mini}
    ],
  },
  {
    "id": 5,
    "city": "Negrelliho Viadukt",
    "address": "Praha 8, Karlín, Prvního pluku\n" +
      "186 00",
    "img": bg5,
    "images": [
      {high: via1, low: via1_mini}, {high: via2, low: via2_mini}, {high: via3, low: via3_mini}, {
        high: via4,
        low: via4_mini
      }
    ],
  },
  {
    "id": 6,
    "city": "Hellichova ulice",
    "address": "Praha 1, Malá Strana\n" +
      "118 00",
    "img": bg6,
    "images": [
      {high: hel1, low: hel1_mini}, {high: hel2, low: hel2_mini}, {high: hel3, low: hel3_mini}, {
        high: hel4,
        low: hel4_mini
      }, {high: hel5, low: hel5_mini}, {high: hel6, low: hel6_mini}, {high: hel7, low: hel7_mini}
    ],
  },
]

export const Galery = () => {

  const params = useParams();
  const id = params.mestoId;

  const city = cities[id - 1];
  const images = city.images;
  console.log(city);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={styles.container}>

        <header className={styles.header}>
          <img className={styles.main_img} alt={'img'} src={city.img} fetchpriority="high"/>
          <div className={styles.headerTopic}>{city.city}</div>
        </header>

        <main className={styles.content}>

          <div id={styles.reference}>

            {images.map((image, index) => (
              <a href={image.high} title="picture" data-lightbox="example-set"
                 className={"example-image-link"} key={index}>
                <img src={image.low} className={styles.galery_img} alt={"1"}/>
              </a>
            ))}
          </div>

          <div className={styles.bottom_content}>
            <Backbutton onClick={() => {
              navigate(-1)
            }}/>
            <div>{city.address}</div>
            <Link to={`/mapa/${city.id}`} className={styles.link}>Podívat se přímo</Link>
          </div>
        </main>
      </div>
    </div>
  )
}