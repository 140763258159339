import {MainSection} from "./mainSection/MainSection";
import {MainBackground} from "./mainBackground/MainBackground";
import {ImageSection} from "./imageSection/ImageSection";
import {ContactSection} from "./contactSection/ContactSection";
import {AboutUsSection} from "./aboutUsSection/AboutUsSection";
import {useEffect} from "react";
import {EcoSection} from "./ecoSection/EcoSection";
import DocumentMeta from 'react-document-meta';

const TITLE = 'OK Dlažby'

const App = () => {

    const meta = {
        title: TITLE,
        description: 'Realizujeme kvalitní pokládky dlažby v Praze a Středočeském kraji.' +
            'Provádíme pokládky chodníků, komunikací, parkovišť nebo kdekoli, kde si zákazník vyžádá.' +
            'V zájmu naší společnosti Zabývá se pokládkou venkovní dlažby všeho druhu. ' +
            'neustále snižovat uhlíkovou stopu' +
            'a nadále přecházet na ekologičtější způsoby realizace.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Dlažby, dlažební práce, ok dlažby, ok stavby, dláždění cest, dláždění parkovišť, ' +
                    'dlažební stavby, silniční práce, práce s dlažbou, dláždění chodníků, silnice, cesty, komunikace, dláždění komunikace,' +
                    'pokládka dlažby, dlažby Praha, dlažba Střední Čechy, dlažba, žula, žulové dlažby, op1, op2, op3, op4, op5, op6, op7, op8' +
                    'chodníky, pokládka chodníků, dlažba Středočeský kraj, dlážděné chodníky, recyklace, venkovní dlažba, dalžba všeho druhu, ' +
                    'dlážděná náměstí'
            }
        }
    }


    useEffect(() => {
        const anchor = window.location.hash.slice(1);
        console.log(anchor)
        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                anchorEl.scrollIntoView();
            }
        }
    }, []);

    return (
        <div>
            <MainBackground/>
            <MainSection/>
            <AboutUsSection/>
            <ImageSection/>
            <EcoSection/>
            <ContactSection/>
        </div>
    );
}

export default App;
