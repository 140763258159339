import styles from './style.module.scss'
import React from "react";
import {FormSection} from "./FormSection";


export const ContactSection = () => {

    return (
        <div className={styles.contactContainer} id={"contact"}>
            <div className={styles.slogan}>
                <p className={styles.slogan1}>Naše&nbsp;práce&nbsp;nás&nbsp;baví!</p>
                <p className={styles.slogan2}>Spojte&nbsp;se&nbsp;s&nbsp;námi!</p>
            </div>

            <div className={styles.reverse}>
                <div className={styles.contact}>
                    <div className={styles.executive}>
                        <p className={styles.contact_grey_box}>V případě zájmu o naše služby se na nás můžete obrátit na
                            kontaktním emailu nebo na telefonu.</p>
                    </div>
                    <div className={styles.container}>
                        <p className={styles.send}><a href="mailto:ladrova@okdlazby.cz">ladrova@okdlazby.cz</a></p>
                        <p className={styles.phone}><a href="tel:00420 606 051 574"> +420 606 051 574</a></p>
                    </div>
                </div>
            </div>

            <div className={styles.map_and_form_section}>
                <div id={styles.mapContainer}>
                    <iframe
                        title={"googleMap"}
                        className={styles.map}
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10198.823313397628!2d14.5002542!3d50.2787511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe37315bbd9298987!2sOK%20Dla%C5%BEby%20s.r.o.!5e0!3m2!1scs!2scz!4v1665925313122!5m2!1scs!2scz"
                        width="600"
                        height="450"
                        allowFullScreen={""}
                        loading={"lazy"}
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>

                {
                    <FormSection/>
                }
            </div>
        </div>)
}
