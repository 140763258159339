import styles from './style.module.scss'

import img1 from './assets/backgrounds/img1_mini.webp'
import img2 from './assets/backgrounds/img2_mini.webp'
import img3 from './assets/backgrounds/img3_mini.webp'
import img5 from './assets/backgrounds/img4_mini.webp'
import img4 from './assets/backgrounds/img5_mini.webp'
import img6 from './assets/backgrounds/img6_mini.webp'

import {useEffect, useRef, useState} from "react";
import {Album, Pointer} from "./assets/icons/icons";
import {Link} from "react-router-dom";

const ImageComponent = (props) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const myRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        setIsVisible(entry.isIntersecting)
      },
      {
        root: null,
        threshold: 0,
        rootMargin: '-140px'
      }
    )
    observer.observe(myRef.current)
  }, [])

  return (
    <div
      className={styles.content + " " + (isVisible ? styles.visible : '')}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      ref={myRef}
    >
      <img
        src={props.src}
        alt={'img'}
        className={styles.image}
        fetchpriority="low"
      />
      {mouseOver
        ?
        <div className={styles.image_container_hover}>
          <Link to={`/galerie/${props.mestoId}`}>
            <Album style={styles}/>
          </Link>
          <Link to={`/mapa/${props.mestoId}`}>
            <Pointer style={styles}/>
          </Link>
        </div>
        :
        <div className={styles.image_container_unhover + " " + (isVisible ? styles.container_visible : '')}>
          {props.place}
        </div>
      }
    </div>
  )
}

export const ImageSection = () => {

  return (
    <div className={styles.grid_container} id={'galery'}>
      <ImageComponent src={img1} place={'Neratovice-Lobkovice'} mestoId={1}/>
      <ImageComponent src={img2} place={'Brandýs nad Labem'} mestoId={2}/>
      <ImageComponent src={img3} place={'Praha 3, Žižkov'} mestoId={3}/>
      <ImageComponent src={img4} place={'Praha 1, Náměstí republiky'} mestoId={4}/>
      <ImageComponent src={img5} place={'Negrelliho viadukt'} mestoId={5}/>
      <ImageComponent src={img6} place={'Praha 1, Hellichova ulice'} mestoId={6}/>
    </div>
  )
}