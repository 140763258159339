import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route, HashRouter,
} from "react-router-dom";
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {StreetView} from "./pages/streetView/StreetView";
import {Galery} from "./pages/galerySection/Galery";
import {FooterSection} from "./pages/footerSection/FooterSection";
import {NavigationSection} from "./pages/navigationSection/NavigationSection";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <HashRouter>
      <NavigationSection/>
      <Routes>
        <Route path="/" element={<App/>}/>
        <Route path="*" element={<App/>}/>
        <Route path="/mapa/:mestoId" element={<StreetView/>}/>
        <Route path="/galerie/:mestoId" element={<Galery/>}/>
      </Routes>
      <FooterSection/>
    </HashRouter>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
