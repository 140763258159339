import styles from './style.module.scss'
import co2 from "./assets/co2-8.png";



export const EcoSection = () => {

    return (
        <div className={styles.aboutUsContainer} id={styles.eco}>
            <div className={styles.aboutUsBackground}>
                <div id={styles.p1}>
                    <p id={styles.big_text}>
                        MÁME POUZE JEDNU PLANETU,
                    </p>
                    <img src={co2} alt={'co2 reduce'}/>
                </div>
                <div id={styles.p3}>
                    <p>
                        a proto je v zájmu naší společnosti
                    </p>
                    <p>
                        neustále snižovat uhlíkovou stopu
                    </p>
                    <p>
                        a nadále přecházet na ekologičtější způsoby realizace.
                    </p>

                </div>
                <div id={styles.p2}>
                    <p>
                        V tomto duchu spolupracujeme s&nbsp;recyklačním střediskem,
                    </p>
                    <p>
                        od kterého odebíráme recyklovaný materiál.
                    </p>
                </div>
            </div>
        </div>
    )
}