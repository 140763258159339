import styles from './style.module.scss'
import logo1 from './assets/logoStavby-removebg-preview.webp'
import logo2 from './assets/darek_jako_vysity_black.png'
import logo3 from './assets/RELAX-8.png'
import logo4 from './assets/ok_recyklace.png'


export const FooterSection = () => {

    // TODO spravne odkazy + loga

    return (
        <div className={styles.footer}>
            <div className={styles.advertisement_part}>
                <div>
                    <a href={"http://www.okstavby.cz/"}>
                        <img src={logo1} alt={'ok stavby'} id={styles.logo1} className={styles.footer_logo} width={'0'} height={'0'}/>
                    </a>
                </div>
                <div>
                    <a>
                        <img src={logo4} alt={'ok recyklace'} id={styles.logo2} className={styles.footer_logo} width={'0'} height={'0'}/>
                    </a>
                </div>
                <div>
                    <a href={"https://www.darekjakovysity.cz/"}>
                        <img src={logo2} alt={'darek jako vysity'} id={styles.logo3} className={styles.footer_logo} width={'0'} height={'0'}/>
                    </a>
                </div>
                <div>
                    <a href={"https://www.penzion-berany.cz/"}>
                        <img src={logo3} alt={'ok relax'} id={styles.logo4} className={styles.footer_logo} width={'0'} height={'0'}/>
                    </a>
                </div>
            </div>
            <div className={styles.grey_part}>
                <div className={styles.info_part}>
                    <div className={styles.partition}>
                        <div className={styles.footer_contact}>
                            <p className={styles.bold}>KONTAKT</p>
                            <p><a href="mailto:ladrova@okdlazby.cz">ladrova@okdlazby.cz</a></p>
                            <p><a href="tel:00420 606 051 574"> +420 606 051 574</a></p>
                        </div>

                        <div className={styles.facturation_info}>
                            <p className={styles.bold}>FAKTURAČNÍ ÚDAJE</p>
                            <p>OK Dlažby s.r.o</p>
                            <p>Mělnická 73/106</p>
                            <p>277 11 Libiš</p>
                            <p>IČO: 27617033</p>
                            <p>DIČ: CZ27617033</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.black_part}>
                © Copyright 2023&nbsp; <a href={"https://digitalnoodles.cz/"}>digitalnoodles.cz</a>
            </div>
        </div>
)
}